.o-nav {
  z-index: 3;

  @include laptopDown {
    position: absolute;
    top: 60px;
    right: 0;
    transform: translateX(100%);
    @include transition(transform .5s ease);

    @include tabletDown {
      top: 0;
    }

    &.active {
      transform: translatex(0);
    }
  }

  @include laptop {
    position: relative;
  }

  &__lang {
    margin-bottom: 30px;
    @include laptopDown {
      padding-left: 10px;
    }
    a {
      margin-right: 20px;
      opacity: .5;
      &.active {
        opacity: 1;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item {
    @include laptopDown {
      padding: 10px;
    }

    @include laptop {
      margin-bottom: 15px;
    }
  }

  &__link {
    font-size: 1.1rem;
    color: $brand-primary-light;

    @include laptop {
      font-size: $small-font-size;
    }
  }
}
