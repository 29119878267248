.o-social-list {
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet {
    justify-content: flex-end;
  }

  @include laptop {

  }

  &__item {
    padding: 2px;

    &-link {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41px;
      height: 41px;
      padding: 10px;
      z-index: 1;
      color: $brand-primary-light;
      font-size: 16px;
      line-height: 0;
      @include transition(color .3s ease);

      .icon-facebook {
        font-size: 20px;
      }

      .icon-instagram {
        font-size: 20px;
      }

      .icon-youtube {
        font-size: 22px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 35px;
        height: 35px;
        margin: auto;
        background-color: $social-icon-bg;
        z-index: -1;
        border-radius: 100px;
      }

      &:hover {
        color: $brand-primary;
      }
    }
  }
}
