.o-image-text {
  position: relative;
  z-index: 1;

  img {
    &.is-bleached {
      opacity: 0.5;
      mix-blend-mode: screen;
    }
  }

  &__text {
    z-index: 2;

    &.is-left {
      margin-left: 20px;
      margin-top: 30px;

      @include tabletDown {
        margin-left: 0;
      }

      @include tablet {
        margin-left: 100px;
        margin-top: 80px;
      }
    }

    &.is-container-right {
      left: 0;
      right: 0;

      @include tabletDown {
        right: 10px;
      }

      > .container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &__text-and-image {
    @include tablet {
      position: absolute;
      right: 0;
      bottom: 24%;
      left: 0;
    }

    .c-ttl {
      position: relative;
      z-index: 1;

      @include laptop {
        transform: translateX(50%);
      }
    }
  }
}
