// Font Weight
$font-weight-black: 900;
$font-weight-heavy: 800;
$font-weight-heading: 700;
$font-weight-semiBold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-thin: 200;

// Font imports
@font-face {
  font-family: "Bird And Thorn";
  src: url("../fonts/BirdAndThorn/BirdAndThorn.eot");
  src: url("../fonts/BirdAndThorn/BirdAndThorn.woff2") format("woff2"),
    url("../fonts/BirdAndThorn/BirdAndThorn.woff") format("woff"),
    url("../fonts/BirdAndThorn/BirdAndThorn.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
  font-display: swap;
}

// Font Size
$body-font-size-mobile: 17px;
$body-font-size-tablet: 18px;
$body-font-size: 20px;

//$xsmall-font-size: .727rem; //16px

$small-font-size: 0.9rem; //18px
//$smallPlus-font-size: .909rem; //20px

$medium-font-size: 1rem; //22px
$mediumPlus-font-size: 1.4rem; //32px

$large-font-size: 2.4rem; //48px
//$largePlus-font-size: 2.181rem; //48px

$xlarge-font-size: 3rem; //60px
$xlargePlus-font-size: 6rem; //120px

// Line height
$line-height-default: 1.2;
$line-height-lg: 1.4;
$line-height-xl: 1.5;

// Letter spacing
$letter-spacing-default: 0.02rem;

// Font Family
$font-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-serif: "Times New Roman", Times, Georgia, Garamond, serif;
$font-family-base: "Gelasio", $font-serif;
$font-family-heading: "Bird And Thorn", $font-sans-serif;
$font-family-code: courier new, Courier, monospace;
