html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
  font-family: $font-family-base;
  font-weight: $font-weight-regular;
  font-size: $body-font-size-mobile;
  line-height: $line-height-default;
  letter-spacing: $letter-spacing-default;
  color: $color-text-neutral;
  background-color: $brand-primary-light;

  @include tablet {
    font-size: $body-font-size-tablet;
  }

  @include laptop {
    font-size: $body-font-size;
  }
}

body {
  //overflow-x: hidden;
}

a {
  color: $color-text-neutral;
}

button {
  cursor: pointer;
  font-weight: inherit;
  
  &[disabled] {
    cursor: not-allowed;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-heading;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 0;

  > li {
    font-size: 1rem;
  }
}

a,
a.c-btn {
  text-decoration: none;
}

body {
  display: flex;
  flex-direction: column;

  html.ie & {
    display: block;
  }

  main {
    flex: 1;
  }
  
}

p {
  line-height: $line-height-xl;
}

hr {
  //border: 0;
  //border-bottom: 1px solid $color-text-neutral;
  //
  //&.white {
  //  border-bottom-color: $white;
  //}
}
