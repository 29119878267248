// Neutral colors
$white: #ffffff;
$black: #000000;


// RGB
$red: #D32542;
$green: #0AB21B;


// Grey
$brand-grey: #454952;


// Brand Primary
$brand-primary: #d7cdc1;
$brand-primary-light: #fef2e3;


// Brand Secondary
$brand-secondary: #6b4a35;


// Brand Ternary
$brand-ternary: #1a0e08;


// Text colors
$color-text-neutral: $brand-primary-light;
$color-text-neutral-light: $color-text-neutral;
$color-text-brown: #342013;


// Components
$social-icon-bg: $color-text-brown;

// Banners

// Customs
