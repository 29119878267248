.o-logo {
  &__link {
    display: block;
    text-align: center;

    .o-footer & {
      @include tablet {
        text-align: left;
      }
    }
  }

  &__img {
    width: 100%;
    max-width: 220px;

    @include mobilePlus {
      max-width: 300px;
    }

    @include tablet {
      max-width: 555px;
    }

    .o-footer & {
      max-width: 123px;
    }
  }
}
