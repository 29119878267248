.o-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding-top: 20px;
  overflow-x: hidden;

  @include mobilePlus {
    padding-top: 30px;
  }

  @include tablet {
    padding-top: 60px;
  }

  @include laptopDown {
    height: 500px;
  }

  @include desktop {
    padding-top: 45px;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
