// Titles
.c-ttl {
  margin: 0 0 .4em;
  font-size: $body-font-size; //20px
  font-weight: $font-weight-regular;

  @include laptop {
    margin: 0 0 1em;
  }

  &--xsm {
    //font-size: $xsmall-font-size; //16px
  }

  &--sm {
    //font-size: $small-font-size; //18px
  }

  &--smp {
    //font-size: $smallPlus-font-size; //20px
  }

  &--md {
    //font-size: $medium-font-size; //24px
  }
  
  &--mdp {
    //font-size: $mediumPlus-font-size; //30px
  }

  &--lg {
    font-size: $large-font-size; //48px
  }

  &--lgp {
    //font-size: $largePlus-font-size; //48px
  }

  &--xl {
    font-size: $xlarge-font-size - .35rem; //60px

    @include tablet {
      font-size: $xlarge-font-size; //60px
    }
  }

  &--xlp {
    font-size: $xlargePlus-font-size; //80px
  }

  // Position variations
  &--center-mid-top {
    position: absolute;
    top: 30%;
    left: 40px;
    z-index: 1;

    @include tablet {
      left: -70px;
    }
  }
}
