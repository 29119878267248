.o-hero {
  min-height: 800px;
  padding-bottom: 50px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../media/app/tb-hero.jpg');

  @include tablet {
    min-height: 1000px;
    padding-bottom: 115px;
  }

  &.is-second {
    align-items: center;
    min-height: 600px;
    padding-bottom: 0;
    background-image: url('../media/app/tunabay-image6.jpg');

    @include tablet {
      min-height: 800px;
    }

    @include laptop {
      min-height: 1000px;
    }

    @include fhd {
      min-height: 1200px;
    }
  }

  &,
  &__inner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &__inner {}
}
