.c-txt {

  &-md {
    font-size: $medium-font-size;

    @include laptop {
      font-size: 1.5rem;
    }
  }

  &-mdp {
    font-size: $medium-font-size;

    @include laptop {
      font-size: $mediumPlus-font-size; //32px
    }
  }
  
  &-lg {}
}
