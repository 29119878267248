.o-footer {
  padding: 40px 0;

  @include tabletDown {
    text-align: center;
  }

  @include tablet {
    padding: 60px 0;
  }

  @include laptop {
    padding: 80px 0;
  }

  &__address {
    margin-top: 10px;
    font-size: 12px;
    line-height:1.33;
    a {
      pointer-events: none;
      color: inherit;
    }
  }
}
