// Link
.c-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    @extend %decoration-underline;
  }

  &-eff {
    &,
    &-rev {
      position: relative;
    }

    &-rev:after,
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -6px;
      width: 0;
      height: 2px;
      background-color: $brand-primary;
      @include transition(width .5s ease);
    }

    &:hover:after {
      width: 100%;
    }

    &-rev {
      &:after {
        width: 100%;
      }

      &:hover:after {
        width: 0;
      }
    }
  }

  &.is-rev {
    @extend %decoration-underline;

    &:hover {
      text-decoration: none;
    }
  }
}

button.c-link,
button.c-link-eff {
  padding: 0;
  border: 0;
  background-color: transparent;
}

button.c-link {
  &:hover {
    text-decoration: none;
  }

  &.has-underline {
    &:hover {
      text-decoration: underline;
    }
  }
}
