.o-transit {
  position: relative;
  z-index: 3;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 25%;
    background-color: $brand-primary-light;

    @include tablet {
      height: 28%;
    }
  }
}
